<template>
  <v-card flat class="px-3">
    <v-row row wrap>
      <v-col xs="2">
        <div class="caption grey--text">Название</div>
        <div>{{ model.title }}</div>
      </v-col>
      <v-col xs="2">
        <div class="caption grey--text">Категория</div>
        <div>{{ model.category.title }}</div>
      </v-col>
      <v-col xs="2">
        <div class="caption grey--text">Производитель</div>
        <div>{{ model.vendor.title }}</div>
      </v-col>
      <v-col xs="2">
        <div class="caption grey--text">Время/дата</div>
        <div>{{ model.updatedAt | moment('YYYY-MM-DD HH:mm:ss') }}</div>
      </v-col>
    </v-row>
    <v-row cols="12">
      <v-divider></v-divider>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    model: Object
  }
}
</script>
