<template>
  <div>
    <h1 class="subtitle-1 grey--text">Модели устройств</h1>

    <v-container class="mb-5">
      <HardwareModelCard v-for="model in hardwareModel.models" :key="model.id" :model="model" />
    </v-container>

    <template v-if="page != 1">
      <router-link :to="{ name: 'hardware-model-list', query: { page: page - 1 } }" rel="prev">Prev Page</router-link>
      <template v-if="hasNextPage"> | </template>
    </template>
    <router-link v-if="hasNextPage" :to="{ name: 'hardware-model-list', query: { page: page + 1 } }" rel="next"> Next Page</router-link>
  </div>
</template>

<script>
import HardwareModelCard from '@/components/HardwareModelCard'
import { mapState } from 'vuex'
import store from '@/store/index'

function getPageModels(routeTo, next) {
  const currentPage = parseInt(routeTo.query.page || 1)
  store
    .dispatch('hardwareModel/fetchPageModels', {
      page: currentPage
    })
    .then(() => {
      routeTo.params.page = currentPage
      next()
    })
}

export default {
  props: {
    page: {
      type: Number,
      required: true
    }
  },
  components: {
    HardwareModelCard
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    getPageModels(routeTo, next)
  },
  beforeRouteUpdate(routeTo, routeFrom, next) {
    getPageModels(routeTo, next)
  },
  computed: {
    hasNextPage() {
      return this.hardwareModel.modelsTotal > this.page * this.perPage
    },
    ...mapState(['hardwareModel', 'user'])
  }
}
</script>
